import { BUILD_ID } from "../../analytics";
import { Override } from "../../types";
import { strToDate } from "../../utils/dates";
import { TransformDomain } from "../types";
import { ReclaimEdition } from "../Users";
import {
  MembershipRole as MembershipRoleDto,
  PartialTeam as PartialTeamDto,
  PartialTeamInvitation as PartialTeamInvitationDto,
  PartialTeamMember as PartialTeamMemberDto,
  ReclaimApi,
  ReclaimEdition as ReclaimEditionDto,
  RequestParams,
  TeamInvitation as TeamInvitationDto,
  TeamInvoice as TeamInvoiceDto,
  TeamMemberView as TeamMemberViewDto,
  TeamView as TeamViewDto,
} from "./client";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export type TeamInvoice = Override<TeamInvoiceDto, {}>;
export const STRIPE_SESSION_URI = `${API_BASE_URI}/team/current/subscription/session`;
export const STRIPE_NEW_SESSION_URI = `${API_BASE_URI}/team/create/subscription/session`;

export enum InvitationStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Deleted = "DELETED",
}

export enum TeamMemberViewStatus {
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
}

export enum MembershipRole {
  User = "USER",
  Admin = "ADMIN",
}

export enum SubscriptionStatus {
  Trialing = "TRIALING",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type TeamMember = Override<
  TeamMemberViewDto,
  {
    readonly edition: ReclaimEdition;
    readonly editionAfterTrial: ReclaimEdition;
    readonly status: TeamMemberViewStatus;
    readonly membershipRole: MembershipRole;
    readonly trialEnd?: Date;
  }
>;

export type Team = Override<
  TeamViewDto,
  {
    readonly id?: number;

    userInviteLevel: ReclaimEdition;
    subscriptionStatus: SubscriptionStatus;
    paidProSeats: number;
    paidTeamSeats: number;
    proSeatsUsed: number;
    teamSeatsUsed: number;
    members: TeamMember[];
  }
>;

export type PartialTeam = Override<
  PartialTeamDto,
  {
    userInviteLevel?: ReclaimEdition | null;
  }
>;

export type PartialTeamMember = Override<
  PartialTeamMemberDto,
  {
    edition?: ReclaimEdition;
    role?: MembershipRole;
  }
>;

export type PartialTeamInvitation = Override<
  PartialTeamInvitationDto,
  {
    role: MembershipRole;
  }
>;

export type TeamInvitation = TeamInvitationDto;

export enum SubscriptionFrequency {
  Month = "MONTH",
  Year = "YEAR",
}

export type DesiredSubscription = { frequency: SubscriptionFrequency; seats: number; edition: ReclaimEdition };

export enum TeamRedirectAction {
  InviteAccepted = "accepted",
  PurchaseSuccess = "purchased",
  PurchaseCancelled = "purchaseCancelled",
}

export const dtoToTeamMember = (dto: TeamMemberViewDto): TeamMember => {
  return {
    ...dto,
    edition: ReclaimEdition.get(dto.edition) || ReclaimEdition.Free,
    editionAfterTrial: ReclaimEdition.get(dto.editionAfterTrial) || ReclaimEdition.Free,
    status: dto.status as unknown as TeamMemberViewStatus,
    membershipRole: dto.membershipRole as unknown as MembershipRole,
    trialEnd: strToDate(dto.trialEnd),
  };
};

export const dtoToTeam = (dto: TeamViewDto): Team => {
  return {
    ...dto,
    subscriptionStatus: dto.subscriptionStatus as unknown as SubscriptionStatus,
    paidProSeats: dto.paidProSeats || 0,
    paidTeamSeats: dto.paidTeamSeats || 0,
    proSeatsUsed: dto.proSeatsUsed || 0,
    teamSeatsUsed: dto.teamSeatsUsed || 0,
    userInviteLevel: ReclaimEdition.get(dto.userInviteLevel) || ReclaimEdition.None,
    members: dto.members?.map(dtoToTeamMember),
  };
};

export const dtoToTeamInvoice = (dto: TeamInvoiceDto): TeamInvoice => {
  return {
    ...dto,
  };
};

export const invitesToDto = (invites: PartialTeamInvitation[]): PartialTeamInvitationDto[] => {
  return invites.map((i) => ({
    ...i,
    role: i.role as unknown as MembershipRoleDto,
  }));
};

export const partialTeamMemberToDto = (member: PartialTeamMember): PartialTeamMemberDto => ({
  ...member,
  role: member.role as unknown as MembershipRoleDto,
  edition: member.edition as unknown as ReclaimEditionDto,
});

export class TeamDomain extends TransformDomain<Team, TeamViewDto> {
  /**
   * The team domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing team module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "Team";
  cacheKey = "team";

  get = (): Promise<Team> => this.domainApi.team.getCurrentTeam().then(dtoToTeam);

  getMembers = (): Promise<TeamMember[]> =>
    this.domainApi.team.getMembers().then((response: TeamMemberViewDto[]) => response.map(dtoToTeamMember));

  listInvoices = (): Promise<TeamInvoice[]> => this.domainApi.team.listInvoices().then((r) => r.map(dtoToTeamInvoice));

  deleteMember = (userId: string) => this.domainApi.team.deleteMember(userId);

  invite = (invites: PartialTeamInvitation[]): Promise<TeamInvitation[]> =>
    this.domainApi.team.createInvitation(invitesToDto(invites));

  patchTeam = (data: PartialTeam, params?: RequestParams) => this.domainApi.team.patchTeam(data, params);
  patchMember = (userId: string, data: PartialTeamMember, params?: RequestParams) =>
    this.domainApi.team.patchMember(userId, partialTeamMemberToDto(data), params);
  getInvitations = (): Promise<TeamInvitation[]> => this.domainApi.team.getInvitations();

  acceptInvitation = (teamId: number, inviteId: string) =>
    this.domainApi.team.acceptInvitation(teamId, inviteId, { accept: true });

  deleteInvitation = (id: string) => this.domainApi.team.deleteInvitation(id);

  startSubscription = ({ frequency, seats, edition }: DesiredSubscription, newTab?: boolean) => {
    const baseRedirect = `${window.location.origin}/billing`;
    const url = new URL(STRIPE_NEW_SESSION_URI);

    url.search = new URLSearchParams({
      successUrl: `${baseRedirect}?action=${TeamRedirectAction.PurchaseSuccess}`,
      cancelUrl: `${baseRedirect}?action=${TeamRedirectAction.PurchaseCancelled}`,
      seats: seats.toString(),
      edition: edition.key,
      frequency,
    }).toString();

    if (!!newTab) {
      window.open(url.toString(), "billing_subscription");
    } else {
      window.location.href = url.toString();
    }
  };
}
