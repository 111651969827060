import { Override } from "../types";
import {
  AbstractLinearTeam as AbstractLinearTeamDto,
  JiraIntegration as JiraIntegrationDto,
  JiraIntegrationPatch as JiraIntegrationPatchDto,
  JiraProject as JiraProjectDto,
  LinearIntegration as LinearIntegrationDto,
  TodoistIntegration as TodoistIntegrationDto,
  TodoistProject as TodoistProjectDto,
  RequestParams,
} from "./client";
import { Domain } from "./types";

const OAUTH_BASE_URI = process.env.NEXT_PUBLIC_OAUTH_BASE_URI;

/* Todoist */

export type TodoistIntegration = Override<
Required<TodoistIntegrationDto>,
{
  availableProjects: TodoistProject[];
}
>;

export type TodoistProject = Override<
  Required<TodoistProjectDto>,
  {}
>;

/* Linear */

export type LinearTeam = Override<
  AbstractLinearTeamDto,
  {
    readonly id: string;
    readonly key: string;
    readonly name: string;
  }
>;

export type LinearIntegration = Override<
  LinearIntegrationDto,
  {
    readonly id: string;
    readonly availableTeams?: LinearTeam[];
  }
>;

/* Jira */

export type JiraProject = Override<
  JiraProjectDto,
  {
    project_key: string;
    project_name: string;
  }
>;

export type JiraIntegration = Override<
  JiraIntegrationDto,
  {
    readonly id: string;
    available_projects?: JiraProject[];
  }
>;

export type JiraIntegrationPatch = Override<
  JiraIntegrationPatchDto,
  {
    installation: string;
    selected_project_keys: string[];
  }
>;

export class IntegrationsDomain extends Domain {
  /* Zoom */

  getZoomIntegration = this.manageErrors(this.api.integrations.getZoomIntegration);

  getZoomEnabled = this.manageErrors(() => {
    return this.api.integrations
      .getZoomIntegration()
      .then((zoom) => !!zoom && !!zoom.id)
      .catch(() => false);
  });

  /* Linear */

  getLinearIntegrations = this.manageErrors(this.api.linear.integrations2);

  patchLinearIntegration = (id: string, data: Partial<LinearIntegration>) => {
    return this.api.linear.patchIntegration2(id, data);
  };

  deleteLinearIntegration = (id: string) => {
    return this.api.linear.deleteIntegration2(id);
  };

  getLinearEnabled = this.manageErrors(() => {
    return this.api.linear
      .integrations2()
      .then((list) => !!list?.length && list.some((i) => !!i.id))
      .catch(() => false);
  });

  /* Todoist */

  getTodoistIntegrations: (params?: RequestParams) => Promise<TodoistIntegration[]> = this.manageErrors(this.api.todoist.integrations3);

  patchTodoistIntegration = (id: number, data: Partial<TodoistIntegration>) => {
    return this.api.todoist.patchIntegration3(id, data);
  };

  deleteTodoistIntegration = (id: number) => {
    return this.api.todoist.deleteIntegration3(id);
  };

  getTodoistEnabled = this.manageErrors(() => {
    return this.api.todoist
      .integrations3()
      .then((list) => !!list?.length && list.some((i) => !!i.id))
      .catch(() => false);
  });

  /* Jira */

  getJiraIntegrations = this.manageErrors(this.api.jira.integrations1);

  patchJiraIntegration = (id: string, patch: JiraIntegrationPatch) => this.api.jira.patchIntegration1(id, patch);

  deleteJiraIntegration = (id: string) =>
    this.api.jira.deleteIntegration1(id).then((response: JiraIntegrationDto[]) => response as JiraIntegration[]);

  getJiraEnabled = () =>
    this.getJiraIntegrations()
      .then((list) => !!list?.length && list.some((i) => !!i.id))
      .catch(() => false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authRedirect(redirectUri: string, state: any, newTab?: boolean) {
    const url = new URL(`${OAUTH_BASE_URI}${redirectUri}`, window.location.href);
    url.searchParams.append("state", JSON.stringify({ redirect: window.location.pathname, ...(state || {}) }));

    if (newTab) {
      window.open(url.toString());
    } else {
      window.location.href = url.toString();
    }
  }
}
